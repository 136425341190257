<template>
    <vue-pdf-embed :source="{ url: invoicePath, withCredentials: true}"/>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";

export default {
    components: {
        VuePdfEmbed,
    },
    props: {
        invoicePath: String
    },
    setup: () => {
        return {}
    }
}
</script>

<style scoped>

</style>
